import React from "react";
import useMentorsipCurriculum from "./use-mentorship-curriculum";
import MentorshipPlanTemplateDetails from "./mentorship-plan-template/mentorship-plan-template-details";

type Props = {};

const MentorshipCurriculum = (props: Props) => {
  const { templateForMonths, handleTemplateForMonths, templateDataToShow } =
    useMentorsipCurriculum();
  return (
    <div className="bg-[#111] rounded-2xl p-5 space-y-6">
      <div className="flex justify-between items-center">
        <div className="space-y-2 max-w-[294px]">
          <p className="text-[23px] font-medium text-white">
            Mentorship Curriculum
          </p>
          <p className="text-gray-400 text-xs leading-[18px] tracking-[-0.18px]">
            Clear breakdown of all the topics the mentor will guide you through
            as part of the long-term mentorship.
          </p>
        </div>
        <div className="flex border border-[#333]">
          {[
            { duration: 6, name: "6 Months" },
            { duration: 3, name: "3 Months" },
            { duration: 1, name: "1 Month" },
          ].map((item, index) => {
            return (
              <div
                className={`p-2 flex justify-center items-center
									  ${templateForMonths === item?.duration ? "border-b border-b-white" : ""}
                    ${index === 1 ? "border-x border-[#333]" : ""}
								`}
                key={item?.duration}
                onClick={() => {
                  handleTemplateForMonths(item?.duration);
                }}
              >
                <p
                  className={`cursor-pointer  text-[10px] leading-4 ${
                    templateForMonths === item?.duration
                      ? "text-white font-bold"
                      : "text-gray-400 font-medium "
                  }`}
                >
                  {item?.name}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="py-3 border-y border-[#222]">
        <div className="py-1.5 px-2 bg-[rgba(255,183,11,0.15)] flex justify-center">
          <p className="text-[11px] text-[#F6B51E] leading-3">
            Discuss the mentee's pain points and outline what all you will cover
            through this mentorship curriculum.
          </p>
        </div>
      </div>

      <MentorshipPlanTemplateDetails mentorshipPlanData={templateDataToShow} />
    </div>
  );
};

export default MentorshipCurriculum;
