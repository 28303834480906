import { useAppSelector } from "state/hooks";
import MenteeStepsSection from "./MenteeStepsSection";
import useRightSide from "./use-right-side";
import Loader from "common/Loader/Loader";
import MentorshipCurriculum from "./mentor-section/mentorship-curriculum/mentorship-curriculum";
import MentorStepsSection from "./mentor-section/mentor-steps/mentor-steps";

const RightSide = () => {
  const ROLE = useAppSelector((state) => state.generalConfigs.join_role);
  const { isCurriculumCompleted, isCurriculumLoading } = useRightSide();
  return (
    <div className="px-6 max-h-full overflow-y-scroll thin-scrollbar">
      {isCurriculumLoading ? (
        <div className="flex justify-center items-center w-full h-[100vh]">
          <Loader />
        </div>
      ) : isCurriculumCompleted ? (
        <MentorshipCurriculum />
      ) : ROLE === "mentor" ? (
        <MentorStepsSection />
      ) : (
        <MenteeStepsSection />
      )}
    </div>
  );
};

export default RightSide;
