import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import ResourcesIcon from "assets/icons/mentorship-plan/template-month/resources-icon.svg";
import ChevronDownIcon from "assets/icons/mentorship-plan/template-month/expand-below-chevron-down.svg";
import {
  MentorshipPlanMentorAssignments,
  MentorshipPlanMentorProjects,
  MentorshipPlanMentorResources,
} from "types/mentorship-plan";
import {
  resourcesAssignmentsProjectsCollapsed,
  resourcesAssignmentsProjectsExpanded,
} from "../../../../analytics/index.analytics";

type ResourceAssignmentProjectsProps = {
  assetsData: {
    mentorshipPlanResourcesData?: MentorshipPlanMentorResources[];
    mentorshipPlanAssignmentsData?: MentorshipPlanMentorAssignments[];
    mentorshipPlanProjectsData?: MentorshipPlanMentorProjects[];
  };
  openedCoveringSection: "" | "Topics" | "Resources";
  handleOpenCoveringSection: (section: "Topics" | "Resources" | "") => void;
  defaultAnalyticsPayload?: any;
};

const ResourceAssignmentProjects = ({
  assetsData,
  openedCoveringSection,
  handleOpenCoveringSection,
  defaultAnalyticsPayload,
}: ResourceAssignmentProjectsProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const handleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  const resourcesCount = assetsData?.mentorshipPlanResourcesData?.length || 0;
  const assignmentsCount =
    assetsData?.mentorshipPlanAssignmentsData?.length || 0;
  const projectsCount = assetsData?.mentorshipPlanProjectsData?.length || 0;

  return (
    <>
      {resourcesCount > 0 || assignmentsCount > 0 || projectsCount > 0 ? (
        <div className="border border-[#333] py-3 px-[14px]">
          <div
            className="flex cursor-pointer items-center justify-between"
            onClick={() => {
              if (openedCoveringSection === "Resources") {
                handleOpenCoveringSection("");
                resourcesAssignmentsProjectsCollapsed({
                  ...defaultAnalyticsPayload,
                  resourcesCount,
                  assignmentsCount,
                  projectsCount,
                });
              } else {
                handleOpenCoveringSection("Resources");
                resourcesAssignmentsProjectsExpanded({
                  ...defaultAnalyticsPayload,
                  resourcesCount,
                  assignmentsCount,
                  projectsCount,
                });
              }
            }}
          >
            <div className="flex items-center gap-3">
              <img src={ChevronDownIcon} alt="chevron-down.svg" />
              <p className="text-xs font-medium text-[#fff]">
                Resources/Assignment/Projects
              </p>
            </div>
            <div className="flex items-center gap-1.5 bg-[rgba(104,47,18,0.40)] px-2 py-1">
              {resourcesCount > 0 ? (
                <p className="text-[11px]  text-[#FF8447]">
                  {resourcesCount} Resources
                </p>
              ) : (
                <></>
              )}
              {resourcesCount > 0 && assignmentsCount < 0 ? (
                <p className="text-[11px]  text-[#FF8447]">•</p>
              ) : (
                <></>
              )}
              {assignmentsCount > 0 ? (
                <p className="text-[11px]  text-[#FF8447]">
                  {assignmentsCount} Assignment
                </p>
              ) : (
                <></>
              )}
              {(resourcesCount > 0 || assignmentsCount > 0) &&
              projectsCount > 0 ? (
                <p className="text-[11px]  text-[#FF8447]">•</p>
              ) : (
                <></>
              )}
              {projectsCount > 0 ? (
                <p className="text-[11px]  text-[#FF8447]">
                  {projectsCount} Projects
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>

          <AnimatePresence>
            {openedCoveringSection === "Resources" && (
              <motion.div
                className="overflow-hidden"
                initial={{ height: 0 }}
                animate={{ height: "auto" }}
                exit={{ height: 0 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
              >
                <div className="mb-[2px] mt-5 h-[1px] w-full bg-[#222]" />

                <div className="divide-y divide-[#333] pl-[34px]">
                  {resourcesCount > 0 ? (
                    assetsData?.mentorshipPlanResourcesData?.map((item) => {
                      return (
                        <div
                          className="flex items-center gap-4 py-[18px]"
                          key={item?.recordId}
                        >
                          {/* <img src={ResourcesIcon} alt="resources.svg" /> */}
                          <p className="text-[11px] text-[#fff]">
                            <span className="font-medium text-[#fff]">
                              Resource:{" "}
                            </span>
                            <span
                              className={`${
                                item?.url
                                  ? "pl-2 underline underline-offset-2"
                                  : ""
                              }`}
                            >
                              {item?.title}
                            </span>
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}

                  {assignmentsCount > 0 ? (
                    assetsData?.mentorshipPlanAssignmentsData?.map((item) => {
                      return (
                        <div
                          className="flex items-center gap-4 py-[18px]"
                          key={item?.recordId}
                        >
                          {/* <AssignmentIcon /> */}
                          <p className="text-[11px] text-[#fff]">
                            <span className="font-medium text-[#fff]">
                              Assignment:{" "}
                            </span>
                            <span
                              className={`${
                                item?.url
                                  ? " pl-2 underline underline-offset-2"
                                  : ""
                              }`}
                            >
                              {item?.title}
                            </span>
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}

                  {projectsCount > 0 ? (
                    assetsData?.mentorshipPlanProjectsData?.map((item) => {
                      return (
                        <div
                          className="flex items-center gap-4 py-[18px]"
                          key={item?.recordId}
                        >
                          {/* <ProjectIcon /> */}
                          <p className={`text-[11px] text-[#fff]`}>
                            <span className="font-medium text-[#fff]">
                              Project:{" "}
                            </span>
                            <span
                              className={`${
                                item?.url
                                  ? "pl-2 underline underline-offset-2"
                                  : ""
                              }`}
                            >
                              {item?.title}
                            </span>
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ResourceAssignmentProjects;
