import { sendAnalyticsToSegment } from "api/analyticsV2";

const expandAllMonthsClicked = (properties: any = {}) => {
	sendAnalyticsToSegment.track('MP - Expand All Months', properties);
};

const collapsedAllMonthsClicked = (properties: any = {}) => {
	sendAnalyticsToSegment.track('MP - Collapsed All Months', properties);
};

const mentorshipMonthExpanded = (properties: any = {}) => {
	sendAnalyticsToSegment.track('MP - Mentorship Month Expanded', properties);
};

const mentorshipMonthCollapsed = (properties: any = {}) => {
	sendAnalyticsToSegment.track('MP - Mentorship Month Collapsed', properties);
};

const topicsForMonthExpanded = (properties: any = {}) => {
	sendAnalyticsToSegment.track('MP - Topics For Month Expanded', properties);
};

const topicsForMonthCollapsed = (properties: any = {}) => {
	sendAnalyticsToSegment.track('MP - Topics For Month Collapsed', properties);
};

const topicDetailsExpanded = (properties: any = {}) => {
	sendAnalyticsToSegment.track('MP - Topic Details Expanded', properties);
};

const topicDetailsCollapsed = (properties: any = {}) => {
	sendAnalyticsToSegment.track('MP - Topic Details Collapsed', properties);
};

const showAllTopicsClicked = (properties: any = {}) => {
	sendAnalyticsToSegment.track('MP - Show All Topics', properties);
};

const resourcesAssignmentsProjectsExpanded = (properties: any = {}) => {
	sendAnalyticsToSegment.track('MP - Resources/Assignments/Projects Expanded', properties);
};

const resourcesAssignmentsProjectsCollapsed = (properties: any = {}) => {
	sendAnalyticsToSegment.track('MP - Resources/Assignments/Projects Collapsed', properties);
};

export {
	collapsedAllMonthsClicked,
	expandAllMonthsClicked,
	mentorshipMonthCollapsed,
	mentorshipMonthExpanded,
	resourcesAssignmentsProjectsCollapsed,
	resourcesAssignmentsProjectsExpanded,
	showAllTopicsClicked,
	topicDetailsCollapsed,
	topicDetailsExpanded,
	topicsForMonthExpanded,
	topicsForMonthCollapsed,
};
