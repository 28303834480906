import React from "react";
import { useAppDispatch, useAppSelector } from "state/hooks";
import ResizeOverlay from "common/ResizeOverlay/ResizeOverlay";
import { setTimelineDropDownState } from "state/timeline/timelineReducer";
import CommonView from "./common-view/CommonView";
import { TrialV4Container } from "views/TrialV4Container";

const NewSteps = () => {
  const dispatch = useAppDispatch();
  // const ROLE = useAppSelector((state) => state.generalConfigs.join_role);

  return (
    <TrialV4Container>
      <ResizeOverlay>
        <main
          className="min-h-screen bg-[#161616] overflow-hidden"
          onClick={() => {
            dispatch(setTimelineDropDownState(false));
          }}
        >
          <CommonView />
          {/* {ROLE === "mentor" ? <MentorView /> : <MenteeView />} */}
        </main>
      </ResizeOverlay>
    </TrialV4Container>
  );
};

export default NewSteps;
